import React, { useRef, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Tab,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { BsCircleFill } from "react-icons/bs";
import { Checkbox } from "@chakra-ui/react";
import "antd/dist/antd.dark.css";
import "./NewScheduling.css";
import { DatePicker, Space } from "antd";
import { TimePicker } from "antd";
import { Tooltip } from "antd";
import moment from "moment";
import { Select, Input } from "antd";
import NumericInputDemo from "components/NumberInput/NumberInput";

const { TextArea } = Input;
const { Option } = Select;
const children = [];

for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const onChangeProfessionals = (value) =>
  console.log(`professional selected ${value}`);

const onChangeCustomers = (value) =>
  console.log(`customer selected ${value}`);

function onChangeSelect(value) {
  console.log(`selected ${value}`);
}

function onChangeDate(date, dateString) {
  console.log(date, dateString);
}

function onChangeTime(time, timeString) {
  console.log(time, timeString);
}

function NewUser() {
  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [schedulingInfo, setSchedulingInfo] = useState({
    name: "",
    email: "",
    phonenumber: "",
  });

  const [activeBullets, setActiveBullets] = useState({
    userInfo: true,
    address: false,
    socials: false,
    profile: false,
  });
  const professionals = [
    { value: "Andressa", label: "Andressa" },
    { value: "Rafaela", label: "Rafaela" },
    { value: "Lurdes", label: "Lurdes" },
  ];
  const customers = [
    { value: "Samuel", label: "Samuel" },
    { value: "Caio", label: "Caio" },
    { value: "Paulo", label: "Paulo" },
    { value: "Lucas", label: "Lucas" },
  ];
  const userInfoTab = useRef();
  const addressTab = useRef();
  const socialsTab = useRef();
  const profileTab = useRef();

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      mt={{ sm: "120px", md: "75px" }}
    >
      <Tabs variant="unstyled" mt="24px">
        <TabList display="flex" align="center" justifyContent="center">
          <Tab
            ref={userInfoTab}
            _focus="none"
            w={{ sm: "80px", md: "200px" }}
            onClick={() =>
              setActiveBullets({
                userInfo: true,
                address: false,
                socials: false,
                profile: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "70px", md: "300px" },
                height: "3px",
                bg: activeBullets.address ? "#fff" : "#182058",
                left: { sm: "12px", md: "42px" },
                top: { sm: activeBullets.userInfo ? "8px" : "6px", md: null },
                position: "absolute",
                bottom: activeBullets.userInfo ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.userInfo ? "#fff" : "brand.200"}
                w="20px"
                h="20px"
                border={activeBullets.userInfo ? "none" : "1px solid"}
                borderColor={activeBullets.userInfo ? "#fff" : "#182058"}
                borderRadius="50%"
                mb="8px"
              />
              <Text
                color="#fff"
                fontWeight={activeBullets.userInfo ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}
              >
                Informações
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={addressTab}
            _focus="none"
            w={{ sm: "80px", md: "200px" }}
            onClick={() =>
              setActiveBullets({
                userInfo: true,
                address: true,
                socials: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "80px", md: "200px" },
                height: "3px",
                bg: activeBullets.socials ? "#fff" : "#182058",
                left: { sm: "12px", md: "32px" },
                top: { sm: activeBullets.address ? "8px" : "8px", md: null },
                position: "absolute",
                bottom: activeBullets.address ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.address ? "#fff" : "brand.200"}
                w="20px"
                h="20px"
                border={activeBullets.address ? "none" : "1px solid"}
                borderColor={activeBullets.address ? "#fff" : "#182058"}
                borderRadius="50%"
                mb="8px"
              />
              <Text
                color="#fff"
                fontWeight={activeBullets.address ? "bold" : "normal"}
                transition="all .3s ease"
                _hover={{ color: "#fff" }}
                display={{ sm: "none", md: "block" }}
              >
                Hora e Lugar
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={socialsTab}
            _focus="none"
            w={{ sm: "80px", md: "200px" }}
            onClick={() =>
              setActiveBullets({
                userInfo: true,
                address: true,
                socials: true,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.socials ? "#fff" : "brand.200"}
                w="20px"
                h="20px"
                border={activeBullets.socials ? "none" : "1px solid"}
                borderColor={activeBullets.socials ? "#fff" : "#182058"}
                borderRadius="50%"
                mb="8px"
              />
              <Text
                color="#fff"
                fontWeight={activeBullets.socials ? "bold" : "normal"}
                transition="all .3s ease"
                _hover={{ color: "#fff" }}
                display={{ sm: "none", md: "block" }}
              >
                Pagamentos
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: "80%", lg: "100%" }} mx="auto">
          <TabPanel>
            <Card>
              <CardHeader mb="40px">
                <Flex direction="column">
                  <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                    Programar Serviços
                  </Text>
                  <Text color="gray.400" fontWeight="500" fontSize="sm">
                    Quem, pra quem e a recorrência?
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Profissional
                      </FormLabel>
                      <Select
                        onChange={onChangeProfessionals}
                        mode="multiple"
                        options={professionals}
                        style={{ width: "100%" }}
                        placeholder="Informe a profissional"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Cliente
                      </FormLabel>
                      <Select
                        onChange={onChangeCustomers}
                        options={customers}
                        style={{ width: "100%" }}
                        placeholder="Informe o cliente"
                      />
                    </FormControl>
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "2fr 1fr 1fr" }}
                      gap="30px"
                    >
                      <FormControl
                        gridColumn={{ sm: "1 / 3", lg: "2fr 1fr 1fr" }}
                      >
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Dias da Semana
                        </FormLabel>
                        <Stack spacing={5} direction="row">
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Segunda
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Terça
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Quarta
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Quinta
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Sexta
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Sábado
                            </Text>
                          </Checkbox>
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "2fr 1fr 1fr" }}
                      gap="30px"
                    >
                      <FormControl
                        gridColumn={{ sm: "1 / 4", lg: "2fr 1fr 1fr" }}
                      >
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Repetições
                        </FormLabel>
                        <Stack spacing={5} direction="row">
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Repete semanalmente
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Repete a cada 15 dias
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Repete a cada 30 dias
                            </Text>
                          </Checkbox>
                          <Checkbox colorScheme="green">
                            <Text
                              color="gray.400"
                              fontWeight="400"
                              fontSize="sm"
                            >
                              Repete anualmente
                            </Text>
                          </Checkbox>
                        </Stack>
                      </FormControl>
                    </Grid>
                  </Stack>
                  <Button
                    variant="brand"
                    alignSelf="flex-end"
                    mt="24px"
                    w="100px"
                    h="35px"
                    onClick={() => addressTab.current.click()}
                  >
                    <Text fontSize="10px" color="#fff">
                      Próximo
                    </Text>
                  </Button>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel>
            <Card>
              <CardHeader mb="40px">
                <Flex direction="column">
                  <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                    Data, hora e lugar
                  </Text>
                  <Text color="gray.400" fontWeight="500" fontSize="sm">
                    Quando e onde?
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "1fr 1fr" }}
                      gap="30px"
                    >
                      <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Data
                        </FormLabel>
                        <DatePicker
                          onChange={onChangeDate}
                          placeholder="Informe a data"
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Horário
                        </FormLabel>
                        <TimePicker
                          placeholder="Informe a hora"
                          onChange={onChangeTime}
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "1fr 1fr" }}
                      gap="30px"
                    >
                      <FormControl gridColumn={{ sm: "1 / 3", lg: "auto" }}>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Tipo de Imóvel
                        </FormLabel>
                        <Select
                          options={professionals}
                          style={{ width: "100%" }}
                          placeholder="Informe o tipo de imóvel"
                        />
                      </FormControl>
                      <FormControl gridColumn={{ sm: "1 / 3", lg: "auto" }}>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Cidade
                        </FormLabel>
                        <Select
                          options={professionals}
                          style={{ width: "100%" }}
                          placeholder="Informe a cidade"
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "1fr 1fr 1fr" }}
                      gap="30px"
                    >
                      <FormControl gridColumn={{ sm: "1 / 3", lg: "auto" }}>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Tipo de Serviço
                        </FormLabel>
                        <Select
                          options={professionals}
                          style={{ width: "100%" }}
                          placeholder="Informe o tipo de serviço"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Pessoa Física ou Jurídica?
                        </FormLabel>
                        <Select
                          options={professionals}
                          style={{ width: "100%" }}
                          placeholder="Informe se é PF ou PJ"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Quantidade de Quartos
                        </FormLabel>
                        <Input
                          bg="#0F1535"
                          border=".5px solid"
                          borderColor="rgba(226, 232, 240, 0.3)"
                          _hover="none"
                          color="#fff"
                          borderRadius="15px"
                          placeholder="Informe a quantidade de quartos"
                          fontSize="xs"
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr ", lg: "1fr" }}
                      gap="30px"
                    >
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Observação{" "}
                        </FormLabel>
                        <TextArea
                          rows={4}
                          placeholder="Informe qualquer observação adicional"
                          minH="120px"
                          fontSize="xs"
                          border=".5px solid"
                          borderColor="rgba(226, 232, 240, 0.3)"
                          color="#fff"
                          bg="#0F1535"
                          borderRadius="15px"
                        />
                      </FormControl>
                    </Grid>
                  </Stack>
                  <Flex justify="space-between">
                    <Button
                      variant="brand"
                      bg={"white"}
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={() => userInfoTab.current.click()}
                    >
                      <Text fontSize="10px" color="gray.700">
                        ANTERIOR
                      </Text>
                    </Button>
                    <Button
                      variant="brand"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={() => socialsTab.current.click()}
                    >
                      <Text fontSize="10px" color="#fff">
                        PRÓXIMO
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel>
            <Card>
              <CardHeader mb="40px">
                <Flex direction="column">
                  <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                    Destinação dos Valores
                  </Text>
                  <Text color="gray.400" fontWeight="500" fontSize="sm">
                    Quanto, para quem e qual a forma?
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns="1fr"
                    templateRows="repeat(3, 1fr)"
                    gap="24px"
                  >
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Valor Cobrado
                      </FormLabel>
                      <NumericInputDemo />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Facebook Account
                      </FormLabel>
                      <Input
                        bg="#0F1535"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        borderRadius="15px"
                        placeholder="http://..."
                        fontSize="xs"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Instagram Account
                      </FormLabel>
                      <Input
                        bg="#0F1535"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        borderRadius="15px"
                        placeholder="http://..."
                        fontSize="xs"
                      />
                    </FormControl>
                  </Grid>
                  <Flex justify="space-between">
                    <Button
                      variant="brand"
                      bg={"white"}
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={() => addressTab.current.click()}
                    >
                      <Text fontSize="10px" color="gray.700">
                        ANTERIOR
                      </Text>
                    </Button>
                    <Button
                      variant="brand"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={() => profileTab.current.click()}
                    >
                      <Text fontSize="10px" color="#fff">
                        PRÓXIMO
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewUser;
