import {
  DocumentIcon,
  HomeIcon,
  PersonIcon,
} from "components/Icons/Icons";
import CRM from "views/Dashboard/CRM.js";
import Default from "views/Dashboard/Default.js";
import NewScheduling from "views/Pages/Schedules/NewScheduling";

const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Default",
        path: "/dashboard/default",
        component: Default,
        layout: "/admin",
      },
      {
        name: "CRM",
        path: "/dashboard/crm",
        component: CRM,
        layout: "/admin",
      },
    ],
  },
  {
    name: "PÁGINAS",
    category: "pages",
    items: [
      {
        name: "Páginas",
        path: "/pages",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "Agendamentos",
            path: "/schedules",
            collapse: true,
            authIcon: <PersonIcon color="inherit" />,
            items: [
              {
                name: "Novo Agendamento",
                path: "/pages/schedules/new-scheduling",
                component: NewScheduling,
                layout: "/admin",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default dashRoutes;
